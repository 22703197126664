export const activityStatus = {
  schedule: "schedule",
  notes: "notes",
};

export const engagementOptions = {
  none: "None",
  little: "Some", // legacy
  some: "Some",
  lot: "A lot",
};

export const LEVEL_REQUEST_STATUS = {
  draft: "draft",
  pending: "pending",
  inReview: "inReview",
  approved: "approved",
  ready: "levelUpReady",
  changesRequested: "changesRequested",
};

export const LEVEL_REQUEST_STATUS_DISPLAYS = {
  [LEVEL_REQUEST_STATUS.draft]: "In progress",
  [LEVEL_REQUEST_STATUS.pending]: "Pending staff review",
  [LEVEL_REQUEST_STATUS.approved]: "Approved",
  [LEVEL_REQUEST_STATUS.changesRequested]: "Changes requested",
  [LEVEL_REQUEST_STATUS.inReview]: "In review",
};

export const LEVEL_REQUEST_STATUS_LABELS = {
  draft: "Draft",
  pending: "Pending",
  inReview: "Pending",
  approved: "Approved!",
  changesRequested: "Changes Requested",
};

export const LEVEL_REQUEST_STATUS_CSS_CLASS = {
  draft: "draft",
  pending: "pending",
  inReview: "pending",
  approved: "approved",
  changesRequested: "changes-requested",
};

export const LEVEL_REQUEST_TAG_CLASS = {
  draft: "spire-tag--default",
  pending: "spire-tag--info",
  inReview: "spire-tag--info",
  approved: "spire-tag--success",
  changesRequested: "spire-tag--error",
};

export const LEVEL_REQUEST_ICON_NAME = {
  draft: "",
  pending: "fa-clock",
  inReview: "fa-clock",
  approved: "fa-check",
  changesRequested: "fa-triangle-exclamation",
};

export const PROGRESS_BAR_CLASS = {
  [LEVEL_REQUEST_STATUS.approved]: "approved",
  [LEVEL_REQUEST_STATUS.pending]: "inactive",
  [LEVEL_REQUEST_STATUS.inReview]: "inactive",
  [LEVEL_REQUEST_STATUS.ready]: "level-up-ready",
  [LEVEL_REQUEST_STATUS.changesRequested]: "changes-requested",
  [LEVEL_REQUEST_STATUS.draft]: "level-up-draft",
};

export const PROGRESS_BAR_ACTIONS = {
  [LEVEL_REQUEST_STATUS.approved]: "View Level-Up",
  [LEVEL_REQUEST_STATUS.pending]: "Edit Level-Up",
  [LEVEL_REQUEST_STATUS.changesRequested]: "Edit Level-Up",
  [LEVEL_REQUEST_STATUS.inReview]: "View Level-Up",
  [LEVEL_REQUEST_STATUS.draft]: "Resume",
  [LEVEL_REQUEST_STATUS.ready]: "Request Level-Up",
};

export const PROGRESS_BAR_LABELS = {
  [LEVEL_REQUEST_STATUS.approved]: "",
  [LEVEL_REQUEST_STATUS.pending]: "Level-Up Review Pending…",
  [LEVEL_REQUEST_STATUS.inReview]: "Level-Up Review Started...",
  [LEVEL_REQUEST_STATUS.changesRequested]: "Level-Up Changes Needed",
  [LEVEL_REQUEST_STATUS.draft]: "Level-Up Request Started...",
  [LEVEL_REQUEST_STATUS.ready]: "Ready for Level-Up Request",
};

// spire-button variant
export const LEVEL_REQUEST_ACTION_BUTTON_VARIANTS = {
  [LEVEL_REQUEST_STATUS.approved]: "primary",
  [LEVEL_REQUEST_STATUS.pending]: "tertiary",
  [LEVEL_REQUEST_STATUS.inReview]: "tertiary",
  [LEVEL_REQUEST_STATUS.changesRequested]: "primary",
  [LEVEL_REQUEST_STATUS.draft]: "primary  ",
  [LEVEL_REQUEST_STATUS.ready]: "primary",
};

export const LEVEL_REQUEST_VIEW_MODE = {
  initialAssessmentEditing: "initialAssessmentEditing",
  initialAssessmentReviewing: "initialAssessmentReviewing",
  reviewing: "reviewing",
  editing: "editing",
  readonly: "readonly",
};

export const NOT_STARTED_YET = {
  default: "Not Started Yet",
};
const REVIEW_STATUS_NOT_STARTED = "not_started";
const REVIEW_STATUS_IN_PROGRESS = "in_progress";
const REVIEW_STATUS_READY_FOR_RELEASE = "ready_for_release";
const REVIEW_STATUS_RELEASED = "released";

export const LEVEL_REQUEST_REVIEW_STATUSES = {
  notStarted: REVIEW_STATUS_NOT_STARTED,
  inProgress: REVIEW_STATUS_IN_PROGRESS,
  readyForRelease: REVIEW_STATUS_READY_FOR_RELEASE,
  released: REVIEW_STATUS_RELEASED,
};

export const LEVEL_REQUEST_REVIEW_STATUS_LABELS = {
  [REVIEW_STATUS_NOT_STARTED]: "Not Started",
  [REVIEW_STATUS_IN_PROGRESS]: "In Progress",
  [REVIEW_STATUS_READY_FOR_RELEASE]: "Ready for Release",
  [REVIEW_STATUS_RELEASED]: "Released",
};

const adminLevelRequestTabValues = {
  notStarted: REVIEW_STATUS_NOT_STARTED,
  inProgress: REVIEW_STATUS_IN_PROGRESS,
  readyForRelease: REVIEW_STATUS_READY_FOR_RELEASE,
  changesRequested: LEVEL_REQUEST_STATUS.changesRequested,
  approved: LEVEL_REQUEST_STATUS.approved,
};

const adminLevelRequestTabNotStarted = {
  text: "Not Started",
  value: adminLevelRequestTabValues.notStarted,
  id: "id-not-started",
  panelId: "not-started-panel",
  status: LEVEL_REQUEST_STATUS.pending,
  reviewStatus: LEVEL_REQUEST_REVIEW_STATUSES.notStarted,
  actionText: "Start Review",
  bulkEditable: false,
};

const adminLevelRequestTabInProgress = {
  text: "In Progress",
  value: adminLevelRequestTabValues.inProgress,
  id: "id-in-progress",
  panelId: "in-progress-panel",
  status: LEVEL_REQUEST_STATUS.pending,
  reviewStatus: LEVEL_REQUEST_REVIEW_STATUSES.inProgress,
  actionText: "Resume",
  bulkEditable: false,
};

const adminLevelRequestTabReadyForRelease = {
  text: "Ready for Release",
  value: adminLevelRequestTabValues.readyForRelease,
  id: "id-ready-for-release",
  panelId: "ready-for-release-panel",
  status: LEVEL_REQUEST_STATUS.pending,
  reviewStatus: LEVEL_REQUEST_REVIEW_STATUSES.readyForRelease,
  actionText: "Review",
  bulkEditable: true,
};

const adminLevelRequestTabChangesRequested = {
  text: "Changes Requested",
  value: adminLevelRequestTabValues.changesRequested,
  id: "id-changes-requested",
  panelId: "changes-requested-panel",
  status: LEVEL_REQUEST_STATUS.changesRequested,
  reviewStatus: LEVEL_REQUEST_REVIEW_STATUSES.released,
  actionText: "View",
  bulkEditable: false,
};

const adminLevelRequestTabApproved = {
  text: "Approved",
  value: adminLevelRequestTabValues.approved,
  id: "id-approved",
  panelId: "approved-panel",
  status: LEVEL_REQUEST_STATUS.approved,
  reviewStatus: LEVEL_REQUEST_REVIEW_STATUSES.released,
  actionText: "View",
  bulkEditable: false,
};

export const ADMIN_LEVEL_REQUEST_TABS = {
  notStarted: adminLevelRequestTabNotStarted,
  inProgress: adminLevelRequestTabInProgress,
  readyForRelease: adminLevelRequestTabReadyForRelease,
  changesRequested: adminLevelRequestTabChangesRequested,
  approved: adminLevelRequestTabApproved,
};

const AUDIENCE_USER = "user";
const AUDIENCE_REVIEWER = "reviewer";

export const AUDIENCE_TYPES = {
  [AUDIENCE_USER]: AUDIENCE_USER,
  [AUDIENCE_REVIEWER]: AUDIENCE_REVIEWER,
};

export const audienceOptions = [
  { label: "Staff Reviewer", value: AUDIENCE_REVIEWER },
  { label: "Participant", value: AUDIENCE_USER },
];

const PROMPT_CONTEXT_LEVEL = "level";
const PROMPT_CONTEXT_OPPORTUNITY = "opportunity";

export const PROMPT_CONTEXT_TYPES = {
  [PROMPT_CONTEXT_LEVEL]: PROMPT_CONTEXT_LEVEL,
  [PROMPT_CONTEXT_OPPORTUNITY]: PROMPT_CONTEXT_OPPORTUNITY,
};

export const promptContextOptions = [
  { label: "Opportunity Reflection", value: PROMPT_CONTEXT_OPPORTUNITY },
  { label: "Level-Up Request", value: PROMPT_CONTEXT_LEVEL },
];

export const opportunityStatuses = {
  blank: "",
  datesPending: "Dates Pending",
  open: "Open Now",
  upcoming: "Upcoming",
  closed: "Closed",
  selfScheduled: "Self-scheduled",
};

export const opportunityTypes = {
  past: "past",
  future: "future",
  notSpecified: "notSpecified",
};

const WHITE = "white";
const GREY_DARK = "greyDark";
const GREY_LIGHT = "greyLight";
const RED = "red";
export const xColorOptions = {
  error: WHITE,
  info: WHITE,
  success: GREY_DARK,
  delete: RED,
  disabled: GREY_LIGHT,
};

export const OPPORTUNITY_SOURCE_PROGRAM_STAFF = "program_staff";
export const OPPORTUNITY_SOURCE_PARTICIPANT = "participant";
export const OPPORTUNITY_SOURCE_SESSIONS_API = "sessions_api";
export const OPPORTUNITY_SOURCE_HAPPENINGS_API = "happenings_api";
export const OPPORTUNITY_SOURCE_ECOACH_SIS_API = "ecoach_sis_api";

export const opportunitySources = {
  program_staff: {
    label: "Staff",
    value: OPPORTUNITY_SOURCE_PROGRAM_STAFF,
  },
  participant: {
    label: "Participant",
    value: OPPORTUNITY_SOURCE_PARTICIPANT,
  },
  sessions_api: {
    label: "Sessions API",
    value: OPPORTUNITY_SOURCE_SESSIONS_API,
  },
  happenings_api: {
    label: "Happenings API",
    value: OPPORTUNITY_SOURCE_HAPPENINGS_API,
  },
  ecoach_sis_api: {
    label: "ECoach SIS API",
    value: OPPORTUNITY_SOURCE_ECOACH_SIS_API,
  },
};

export const opportunitySchedulingOptions = {
  predefined: {
    label: "Predefined",
    value: "predefined",
  },
  selfScheduled: {
    label: "Self-scheduled",
    value: "self-scheduled",
  },
};

export const opportunityAdvancedOptions = {
  showArchived: {
    label: "Show Archived",
    value: "show_archived",
  },
  showParticipantCreated: {
    label: "Show Participant Created",
    value: "show_participant_created",
  },
};

// NOTE: If you're using this, it likely means the controller is not configured to correctly return error responses
export const RESPONSE_STATUSES = {
  success: "success",
  failure: "failure",
};

const draft = "draft";
const needsReview = "needs_review";
const published = "published";
const archived = "archived";

export const opportunityAdminStatuses = {
  draft,
  needsReview,
  published,
  archived,
};

export const opportunityAdminStatusLabels = {
  archived: "Archived",
  draft: "Draft",
  needsReview: "Needs Review",
  published: "Published",
  underReview: "Under Review",
};

export const opportunityAdminStatusOptions = [
  { label: opportunityAdminStatusLabels.draft, value: draft },
  { label: opportunityAdminStatusLabels.needsReview, value: needsReview },
  { label: opportunityAdminStatusLabels.published, value: published },
  { label: opportunityAdminStatusLabels.archived, value: archived },
];

export const opportunityAdminStaffStatusOptions = [
  { label: opportunityAdminStatusLabels.draft, value: draft },
  { label: opportunityAdminStatusLabels.underReview, value: needsReview },
  { label: opportunityAdminStatusLabels.published, value: published },
  { label: opportunityAdminStatusLabels.archived, value: archived },
];

export const opportunityAdminEditStaffStatusOptions = [
  { label: opportunityAdminStatusLabels.draft, value: draft },
  { label: opportunityAdminStatusLabels.underReview, value: needsReview },
];

export const engagementPhases = {
  planning: "Planning",
  working: "Working",
  reflecting: "Reflecting",
  synthesizing: "Synthesizing",
};

export const plannedOpportunityStates = {
  todo: "to-do",
  done: "done",
};

export const announcementContextTypes = {
  email: "email",
  message: "message",
  progress: "progress_email",
  actionItem: "action_item_email",
  summary: "summary_email",
};

export const announcementContextTypeOptions = {
  [announcementContextTypes.email]: "Email",
  [announcementContextTypes.progress]: "Progress Email",
  [announcementContextTypes.actionItem]: "Action Item Email",
  [announcementContextTypes.summary]: "Summary Email",
};

export const scheduledAnnouncementContextTypeOptions = {
  [announcementContextTypes.email]: "Email",
  [announcementContextTypes.progress]: "Progress Email",
  [announcementContextTypes.actionItem]: "Action Item Email",
};

export const activityEventTypes = {
  LEVEL_UP_SUBMITTED: "level_request_submitted",
  OPPORTUNITY_COMPLETED: "opportunity_completed",
  OPPORTUNITY_PLANNED: "opportunity_planned",
  LEVEL_UP_APPROVED: "level_request_approved",
  REFLECTION_SUBMITTED: "reflection_submitted",
};

const oneDay = "one_day";
const multipleDay = "multiple_day";
const weeks = "weeks";
const semester = "semester";
const multipleSemester = "multiple_semester";
export const opportunityDurations = {
  oneDay,
  multipleDay,
  weeks,
  semester,
  multipleSemester,
};
export const opportunityDurationOptions = [
  { label: "One Day Event", value: oneDay },
  { label: "Multiple Day Event", value: multipleDay },
  { label: "Several Weeks", value: weeks },
  { label: "Semester-Long", value: semester },
  { label: "Multi-Semester", value: multipleSemester },
];

const ADMIN = "admin";
const PROFESSOR = "professor";
const GSI = "gsi";
const STUDENT = "student";
const OBSERVER = "observer";
export const mappedProgramUserRoles = {
  [ADMIN]: {
    value: ADMIN,
    label: "Super Admin",
  },
  [PROFESSOR]: {
    value: PROFESSOR,
    label: "Program Admin",
  },
  [GSI]: {
    value: GSI,
    label: "Program Staff",
  },
  [STUDENT]: {
    value: STUDENT,
    label: "Student",
  },
  [OBSERVER]: {
    value: OBSERVER,
    label: "Observer",
  },
};

export const announcementCadence = {
  startOfMonth: "start_of_month",
};

export const announcementCadenceOptions = [
  {
    label: "Start of Every Month",
    value: announcementCadence.startOfMonth,
  },
];

export const announcementRecipientGroup = {
  activeStudents: "active_students",
};

export const announcementRecipientGroupOptions = [
  {
    label: "Students Who Have Logged In To This Program",
    value: announcementRecipientGroup.activeStudents,
  },
];

export const buttonColors = {
  white: "#FFFFFF",
  blueMid: "#0064D8",
  greyDark: "#3B3B3B",
  greyLight: "#DEE3E5",
  red: "#ED4C21",
};
const PROGRAM = "use_program_name_and_email";
const REVIEWER = "use_reviewer_name_and_email";
export const mappedReviewerSignatures = {
  reviewer: { value: REVIEWER, label: "Reviewer name and email" },
  program: { value: PROGRAM, label: "Program name and email" },
};

export const defaultProgramTexts = {
  opportunityOverview:
    "<p>Below you'll find opportunities for you to grow your competencies. We highlight some for you here, but you can also add your own.</p>",
  badgeOverview:
    "<p>The following badges are available for you to earn in this program. Some badges can be earned automatically by performing certain actions. Click on a badge to learn more about it.</p>",
  careerOverview:
    "<p>Below you'll find careers that are related to the competencies in this program. Click on a career to learn more about it.</p>",
  competencyOverview:
    "<p>This program includes the following competencies, each with different dimensions. Within each competency and dimension, you can work your way up through the levels.</p>",
  myAccomplishments:
    "<p>Here's everything you've accomplished within Spire. We've organized it so you can use it to tell your story in your resumes, cover letters, and so on.</p>",
  myLevelUpRequests:
    "<p>View all of your level-up requests below. They can be in a state of draft, pending review, changes requested, or approved.</p>",
  reviewProcessExplanation: "This request will be reviewed by program staff.",
};

export const DEFAULT_MAX_COMPETENCIES = 3;

export const SLIDE_TYPE_DEFAULT = "default";
export const SLIDE_TYPE_BADGE = "badge";
export const SLIDE_TYPE_COMPETENCY = "competency";
export const SLIDE_TYPE_OPPORTUNITY = "opportunity";
export const SLIDE_TYPE_UNLOCK_CONDITION = "unlock_condition";

export const SLIDE_TYPES = {
  [SLIDE_TYPE_DEFAULT]: SLIDE_TYPE_DEFAULT,
  [SLIDE_TYPE_BADGE]: SLIDE_TYPE_BADGE,
  [SLIDE_TYPE_COMPETENCY]: SLIDE_TYPE_COMPETENCY,
  [SLIDE_TYPE_OPPORTUNITY]: SLIDE_TYPE_OPPORTUNITY,
  [SLIDE_TYPE_UNLOCK_CONDITION]: SLIDE_TYPE_UNLOCK_CONDITION,
};

export const slideTypeOptions = [
  { label: "Default", value: SLIDE_TYPE_DEFAULT },
  // { label: "Badge", value: SLIDE_TYPE_BADGE },
  { label: "Competency", value: SLIDE_TYPE_COMPETENCY },
  { label: "Opportunity", value: SLIDE_TYPE_OPPORTUNITY },
  // { label: "Unlock Condition", value: SLIDE_TYPE_UNLOCK_CONDITION },
];

export const SIDENAV_BREAKPOINT = 800;

export const ERROR_MESSAGES = {
  deletionFailed: "Unexpected error occurred while deleting.",
};

export const COMPETENCY_DEFAULT_SORT = "name asc";
export const COMPETENCY_NAME_DESC_SORT = "name desc";
export const COMPETENCY_LEVEL_ASC_SORT = "currentLevel asc";
export const COMPETENCY_LEVEL_DESC_SORT = "currentLevel desc";
export const COMPETENCY_SORT_OPTIONS = [
  { label: "Name (A-Z)", value: COMPETENCY_DEFAULT_SORT },
  { label: "Name (Z-A)", value: COMPETENCY_NAME_DESC_SORT },
];
export const COMPETENCY_LEVEL_SORT_OPTIONS = [
  { label: "Current Level (Low to High)", value: COMPETENCY_LEVEL_ASC_SORT },
  { label: "Current Level (High to Low)", value: COMPETENCY_LEVEL_DESC_SORT },
];

export const OPPORTUNITY_REMOVE_CONFIRMATION_TEXT =
  "Are you sure you want to remove this opportunity from your planner? This will also delete the associated reflection, if one exists.";

export const RECIPIENT_TYPE_NO_ACTION_REQUIRED = "no_action_required";
export const RECIPIENT_TYPE_NO_EXERCISE_SELECTIONS = "no_exercise_selections";
export const RECIPIENT_TYPE_NO_FOCUSED_COMPETENCIES = "no_focused_competencies";
export const RECIPIENT_TYPE_NO_PLANNED_OPPORTUNITIES_EVER =
  "no_planned_opportunities_ever";
export const RECIPIENT_TYPE_HAS_CHANGES_REQUESTED_LEVELUP =
  "has_changes_requested_levelup";
export const RECIPIENT_TYPE_HAS_DRAFT_LEVELUP = "has_draft_levelup";
export const RECIPIENT_TYPE_HAS_DRAFT_REFLECTION = "has_draft_reflection";
export const RECIPIENT_TYPE_NEEDS_REFLECTION = "needs_reflection";
export const RECIPIENT_TYPE_NO_RECENT_LOGIN = "no_recent_login";
export const RECIPIENT_TYPE_NEEDS_TO_PLAN = "needs_to_plan";

export const RECIPIENT_TYPE_LABELS = {
  [RECIPIENT_TYPE_NO_ACTION_REQUIRED]: "No Action Required",
  [RECIPIENT_TYPE_NO_EXERCISE_SELECTIONS]: "No Exercise Selections",
  [RECIPIENT_TYPE_NO_FOCUSED_COMPETENCIES]: "No Focused Competencies",
  [RECIPIENT_TYPE_NO_PLANNED_OPPORTUNITIES_EVER]:
    "No Planned Opportunities Ever",
  [RECIPIENT_TYPE_HAS_CHANGES_REQUESTED_LEVELUP]:
    "Has Changes Requested Level-Up",
  [RECIPIENT_TYPE_HAS_DRAFT_LEVELUP]: "Has Draft Level-Up",
  [RECIPIENT_TYPE_HAS_DRAFT_REFLECTION]: "Has Draft Reflection",
  [RECIPIENT_TYPE_NEEDS_REFLECTION]: "Needs Reflection",
  [RECIPIENT_TYPE_NO_RECENT_LOGIN]: "No Recent Login",
  [RECIPIENT_TYPE_NEEDS_TO_PLAN]: "Needs to Plan",
};
